<template>
  <div>
    <div class='row'>
      <div class='col-12'>
        <div class='card card-top card-top-primary'>
          <div class='card-header'>
            <vb-headers-card-header :data="{ title: 'Yayınlara En Çok Katılan Kullanıcılar' }" />
          </div>
          <div class='card-body'>
            <!-- TABLO -->
            <div class='table-responsive text-nowrap'>
              <a-table
                :locale='{emptyText: "Henüz Aktivite Yok"}'
                :pagination='false'
                :columns='columns'
                :data-source='topCustomersItems'
                :row-key='(record) => record.id'
                :loading='loading'
              >
                <template #id='{record}'>
                  <span class='font-weight-bold'>{{ record.id }}</span>
                </template>
                <template #avatar='{record}'>
                  <div class='vb__utils__avatar'>
                    <a-image
                      :src='record.avatar.avatar4X'
                      alt='User'
                      fallback='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
                    />
                  </div>
                </template>
                <template #fullName='{record}'>
                  <span>{{ record.fullName }}</span>
                </template>
                <template #email='{record}'>
                  <span>{{ record.email }}</span>
                </template>
                <template #referencedPT='{record}'>
                  <div class='vb__utils__avatar'>
                    <a-image
                      :src='record.referencedPersonalTrainer ? record.referencedPersonalTrainer.avatar?.avatar4X : " "'
                      alt='avatar'
                      fallback='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
                    />
                  </div>
                  <span class='ml-2'
                        :class='!record.referencedPersonalTrainer ? "font-italic text-gray-3" : ""'>
                      {{ record.referencedPersonalTrainer ? record.referencedPersonalTrainer.name + ' ' + record.referencedPersonalTrainer.surname : 'Referans Yok' }}
                    </span>
                </template>
                <template #premium='{record}'>
                  <span>{{ record.premium ? 'Evet' : 'Hayır' }}</span>
                </template>
              </a-table>
            </div>
            <div class='card-body'>
              <div class='clearfix'>
                <a-pagination
                  class='float-right'
                  @change='loadTopCustomers'
                  v-model:page-size='pagination.recordsPerPage'
                  :default-page-size='pagination.recordsPerPage'
                  v-model:current='pagination.currentPage'
                  :default-current='pagination.currentPage'
                  :total='pagination.totalRecords'
                  :show-total='total => `Toplam ${total} kayıt`'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CustomerAdministration } from '../../services/api-service'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    slots: { customRender: 'id' },
  },
  {
    title:'Avatar',
    dataIndex: 'avatar',
    slots: { customRender: 'avatar' },
  },
  {
    title: 'Kullanıcı Bilgisi',
    dataIndex: 'fullName',
    slots: { customRender: 'fullName' },
  },
  {
    title: 'Mail Adresi',
    dataIndex: 'email',
    slots: { customRender: 'email' },
  },
  {
    title: 'Referans',
    dataIndex: 'referencedPersonalTrainer',
    class: 'd-flex align-items-center',
    slots: { customRender: 'referencedPT' },
  },
  {
    title: 'Premium',
    dataIndex: 'premium',
    class: 'w-100',
    slots: { customRender: 'premium' },
  },
]
export default {
  name: 'VbRozetler',
  data() {
    return {
      columns,
      loading: false,
      topCustomersItems: [],
      orderByJoinCountOfBroadcast: true,
      pagination: { totalPages: 1, currentPage: 1, totalRecords: 0, recordsPerPage: 1 },
    }
  },
  mounted() {
    this.loadTopCustomers()
  },
  methods: {
    async loadTopCustomers() {
      this.loading = true
      try {
        const result = await CustomerAdministration.listCustomersForAdministration(this.orderByJoinCountOfBroadcast, this.pagination.currentPage)
        this.topCustomersItems = result.data.customers
        this.pagination = result.data.pagination
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
  },
}
</script>
